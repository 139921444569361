<ejs-grid
  #grid
  width="100%"
  [height]="height + 'px'"
  [class.alt-row-color]="config.props?.altRowColor"
  [dataSource]="dataManager"
  [showColumnChooser]="config.props?.showColumnChooser"
  [toolbar]="toolbarItems.length ? toolbarItems : undefined"
  [contextMenuItems]="contextMenuItems"
  [allowResizing]="true"
  [allowReordering]="true"
  [allowPaging]="!!pageSettings"
  [pageSettings]="pageSettings"
  [allowFiltering]="!!config.props?.filterSettings"
  [filterSettings]="config.props?.filterSettings"
  [allowSorting]="!!config.props?.sortSettings"
  [sortSettings]="config.props?.sortSettings"
  [allowGrouping]="!!config.props?.groupSettings"
  [groupSettings]="config.props?.groupSettings"
  [editSettings]="config.props?.formSettings ? editSettings : undefined"
  (actionBegin)="actionBegin($event)"
>
  <e-columns>
    <e-column
      *ngFor="let column of columnsR.value$ | async"
      [visible]="column.visible ?? true"
      [headerText]="column.headerTextTr ? (column.headerTextTr | intl) : (column.headerText | dataReference : dataReferenceContext)"
      [field]="column.field"
      [type]="column.type"
      [format]="column.format"
      [width]="column.width"
      [headerTextAlign]="column.headerTextAlign"
      [textAlign]="column.textAlign"
      [autoFit]="column.autoFit"
      [displayAsCheckBox]="column.displayAsCheckBox"
      [formatter]="formatters[column.formatter ?? '']"
      [allowFiltering]="column.allowFiltering"
      [allowSorting]="column.allowSorting"
    >
    </e-column>
  </e-columns>
</ejs-grid>

<ng-template #settingsTemplate>
  <button ejs-button cssClass="e-flat" iconCss="e-icons e-add" (click)="addSettingsProfile()"></button>

  <ng-container *ngIf="selectedSettingsIdR.value$ | async as selectedSettingsId">
    <ng-container *ngIf="selectedSettingsId !== -1">
      <button ejs-button cssClass="e-flat" iconCss="e-icons e-save" (click)="saveSettingsProfile()"></button>
      <button ejs-button cssClass="e-flat" iconCss="e-icons e-delete" (click)="deleteSettingsProfile()"></button>
    </ng-container>
  </ng-container>

  <ejs-dropdownlist
    placeholder="Profil"
    [dataSource]="settingsDropdownDataR.value$ | async"
    [index]="selectedSettingsIndexR.value$ | async"
    (change)="settingsDropdownChange($event)"
  ></ejs-dropdownlist>

  <div style="width: 24px"></div>
</ng-template>
